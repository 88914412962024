<template>
	<div class="input-container">
		<ValidationProvider :name="id" :rules="rules" v-slot="{ errors }" :vid="vid">
			<label :for="id">{{ label }}</label>
			<div class="input-wrapper">
				<img :src="iconImage" v-if="iconImage"/>
				<template v-if="!noInputValue">
					<input
					:id="id"
					:type="type === 'password' ? (showPassword ? 'text' : 'password') : type"
					:value="value"
					:autoComplete="autoComplete"
					:placeholder="placeholder"
					:readonly="readonly"
					:disabled="disabled"
					:pattern="pattern"
					:inputmode="inputMode"
					v-model="inputValue"
					@input="emitChange"
					:maxLength="maxLength"
					:style="{'padding-left': !iconImage ? '1rem' : '0'}"
				/>
				</template>
				<!-- used in invite friends where v-model is used when called the component instead of inside the component -->
				<template v-else>
					<input 
					:id="id" 
					:type="type === 'password' ? (showPassword ? 'text' : 'password') : type" 
					:value="value" 
					:autoComplete="autoComplete" 
					:placeholder="placeholder" 
					:readonly="readonly" 
					:disabled="disabled" 
					:pattern="pattern" 
					:inputmode="inputMode" 
					@input="emitChange" 
					:maxLength="maxLength" 
					:style="{'padding-left': !iconImage ? '1rem' : '0'}" />
				</template>

				<span v-if="type == 'password'" @click="onPasswordVisibleClick">
					<font-awesome-icon :icon="eyeIcon" />
				</span>
			</div>
			<small class="error">{{ errors[0] }}</small>
		</ValidationProvider>
	</div>
</template>

<script>
	import passwordIcon from "../assets/icons/password.png";
	import emailIcon from "../assets/icons/email.png";
	import genderIcon from "../assets/icons/gender.png";
	import telephoneIcon from "../assets/icons/telephone.png";
	import userIcon from "../assets/icons/user.png";


	export default {
		props: [
			"id",
			"label",
			"type",
			"value",
			"autoComplete",
			"placeholder",
			"readonly",
			"disabled",
			"pattern",
			"inputMode",
			"hasIcon",
			"iconType",
			"rules",
			'noInputValue',
      "vid",
      "maxLength"
		],
		watch: {
			inputValue(value) {
				this.$emit("change", value);
			},
			value(value){
				this.inputValue = value;
			}
		},
		data() {
			return {
				inputValue: this.value,
				showPassword: false,
				eyeIcon: "eye-slash",
				email: emailIcon,
				password: passwordIcon,
				gender: genderIcon,
				telephone: telephoneIcon,
				user: userIcon,
			};
		},
		computed: {
			iconImage() {
				switch (this.iconType) {
					case "email":
						return this.email;
						break;
					case "password":
						return this.password;
						break;
					case "gender":
						return this.gender;
						break;
					case "telephone":
						return this.telephone;
						break;
					case "user":
						return this.user;
						break;
					default:
						return "";
						break;
				}
			},
		},
		methods: {
			onPasswordVisibleClick() {
				(this.showPassword = !this.showPassword), (this.eyeIcon = this.eyeIcon === "eye-slash" ? "eye" : "eye-slash");
			},
			emitChange(e){
				this.$emit('input', e.target.value)
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-bottom: 0;
		height: min-content;

		&.bottom-spacing {
      margin-bottom: 2rem;
      
      @media screen and (max-width:768px){
        margin-bottom: 1.5rem;
      }
		}

		&.last {
			margin-bottom: 1.2rem;
		}

		label {
			text-align: left;
			display: block;
			margin-bottom: 5px;
			color: var(--gray414241);
			font-size: 14px;
		}

		.input-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			border: 1px solid var(--borderGrayECECEC);
			border-radius: 5px;
			overflow: hidden;

			img {
				object-fit: contain;
				margin: 0 1rem;
				max-width: 20px;
				height: auto;
			}

			input {
				font-size: 16px;
				letter-spacing: 1px;
				width: 100%;
				border: none;
				padding: 1rem 0;
				color: var(--gray414241);
        
        @media screen and (max-width:450px){
          font-size: 14px;
        }

				&:active,
				&:focus {
					outline: none;
					border: none;
				}

				&:required,
				&:invalid {
					box-shadow: none;
				}
			}

			span {
				cursor: pointer;
				position: absolute;
				right: 20px;
			}
		}

		.error {
			color: red;
			text-align: left;
			display: block;
		}
	}
</style>

<template>
	<ValidationObserver v-slot="{ handleSubmit }" style="width:100%">
		<form :class="{ spacer: addSpacer }" @submit.prevent="handleSubmit(submitForm)">
			<h2 v-if="title">{{ title }}</h2>
			<slot></slot>
		</form>
	</ValidationObserver>
</template>

<script>
	export default {
		props: ["title", "addSpacer"],
		methods: {
			submitForm() {
				this.$emit("submit");
			},
		},
	};
</script>

<style lang="scss">
	form {
		width: 100%;

		h2 {
			display: block;
			text-align: left;
			color: var(--gray414241);
			padding-bottom: 2rem;
		}

		&.spacer {
      padding-top: 5rem;
      
      @media screen and (max-width:450px){
        padding-top: 2rem;
      }
		}
	}
</style>

import axios from "@/axios.config.js";

const ratingStore = (body) => axios.post('/api/v1/ratings',body);

const contacts = (body) => axios.post('/api/v1/contacts',body);

const invites = (body) => axios.post('/api/v1/invites',body);

const exchanges = () => axios.get('/api/v1/exchanges');

const products = (exchange = "") => axios.get(`/api/v1/products${exchange ? '?exchange=' + exchange : '/'}`);

const attachments = (body, token) => axios.post('/api/v1/attachments', body)

export {ratingStore, contacts, invites, exchanges, products, attachments}

<template>
  <ContentWrapper>
    <template v-slot:title>Contact Us</template>
    <Container>
      <Form class="contact-form">
        <div class="contact-method">
          <p>Would you like us to contact you through email or phone number?</p>
          <RadioButton
            v-model="contact_type"
            :options="[
              {
                id: 'EMAIL',
                label: 'Email',
              },
              {
                id: 'PHONE',
                label: 'Phone Number',
              },
            ]"
            name="contact"
          ></RadioButton>
          <p>Subject</p>
          <Input
            id="subject"
            type="text"
            placeholder="Write your subject"
            v-model="subject"
          />

          <p>Message</p>
          <TextArea
            id="message"
            placeholder="Write your message..."
            rows="5"
            v-model="message"
          />

          <SubmitButton @onButtonClick="sendContactForm">Send</SubmitButton>
        </div>
        <div class="contact-options">
          <div class="helpline">
            <p>Kenanga Futures Helpline</p>
            <h3>+603 2172 3860</h3>
          </div>
          <div class="email-support">
            <p>Support Email</p>
            <h3>BCRM@kenanga.com.my</h3>
          </div>
        </div>
      </Form>
    </Container>
  </ContentWrapper>
</template>

<script>
import Form from "@/components/Form";
import RadioButton from "@/components/RadioButton";
import Input from "@/components/Input";
import TextArea from "@/components/TextArea";
import SubmitButton from "@/components/SubmitButton";

import { contacts } from "@/services/api/others.js";

import { eventBus } from "@/main.js";
export default {
  components: {
    Form,
    RadioButton,
    Input,
    TextArea,
    SubmitButton,
  },
  data() {
    return {
      contact_type: "EMAIL",
      subject: "",
      message: "",
    };
  },
  methods: {
    async sendContactForm() {
      try {
        eventBus.$emit("loader", true);
        await contacts({
          contact_type: this.contact_type,
          subject: this.subject,
          message: this.message,
        });
        eventBus.$emit("loader", false);
        this.$swal({
          icon: "success",
          title: "Thank you for contacting us!",
          text: "Our representative will get in touch with you soon",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      } catch (error) {
        eventBus.$emit("loader", false);
        this.$swal({
          icon: "error",
          title: "Oops!",
          timer: 2000,
          text: error.response.data.userMessage,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: var(--gray414241);
  font-size: 18px;
  margin: 1rem 0;
  font-weight: 700;
}

.content-container {
  margin: 0 auto;
  min-height: calc(100vh - 160px);

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 90px);
  }

  @media screen and (max-width: 600px) {
    min-height: initial;
    height: calc(100vh - 60px - 58px);
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    margin: 0;
    padding: 30px 20px;
  }
}

.contact-form {
  display: block;
  margin: 3rem auto 1.5rem;
  max-width: 600px;

  @media screen and (max-width: 450px) {
    margin: 0 auto;
    margin-bottom: 1rem;
  }
}

.submit-button {
  margin: 3rem auto;
  margin-bottom: 2rem;
}

.contact-options {
  display: flex;
  justify-content: space-evenly;

  p {
    margin: 0;
    font-size: 16px;
  }
}

@include respond(phone) {
  p {
    font-size: 13px;
  }
  .submit-button {
    margin-bottom: 3rem;
  }
  .contact-options {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .helpline {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px thin #eee;
    }
    .email-support {
    }
    p {
      margin-bottom: 0;
    }
  }
}
</style>

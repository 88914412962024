<template>
  <div class="radio-button-wrapper">
    <div class="radio-button" v-for="option in options" :key="option.id" >
      <input type="radio" :name="name" :id="option.id" :value="option.id"  :checked="value === option.id" @change="$emit('input',option.id)">
      <label :for="option.id" :key="option.id">{{option.label}}</label>
    </div>
  </div>
</template>

<script>
export default {
  props:['options','name', 'value']
}
</script>

<style lang="scss" scoped>

  .radio-button-wrapper{
    display: flex;
  }

  .radio-button{
    margin-right: 2rem;
  }

  input{
    margin-right: 1rem;
  }

  label{
    @media screen and (max-width:450px){
      font-size: 13px;
    }
  }
</style>
<template>
	<div :class="[{'top-spacer': topSpacer},'textarea-container']">
    <label :for="id">{{ label }}</label>
    <div class="textarea-wrapper">
      <textarea
        :id="id"
        :placeholder="placeholder"
        :cols="cols"
        :rows="rows"
		@input="handleInput"
		:value="value"
		:disabled="disabled"
		:maxlength="maxLength"
      ></textarea>
    </div>
	</div>
</template>

<script>
	export default {
		props: [
			"id",
			"label",
			"type",
			"value",
			"placeholder",
			"cols",
			"rows",
			"topSpacer",
			"disabled",
			"maxLength"
		],
		watch: {
			inputValue(value) {
				this.$emit("change", this.id, value);
			},
		},
		data() {
			return {
				inputValue: "",
			};
		},
		methods: {
			handleInput(e){
				this.$emit('input', e.target.value)
			}
		}
	};
</script>

<style lang="scss" scoped>

  .top-spacer{
    margin-top: 3rem;

    @media screen and (max-width:450px){
      margin-top: 1.5rem;
    }
  }

	.textarea-container {
		margin-bottom: 0;
		height: min-content;
		&.bottom-spacing {
			margin-bottom: 3rem;
		}

		&.last {
			margin-bottom: 1.2rem;
		}

		label {
			text-align: left;
			display: block;
			margin-bottom: 5px;
			color: var(--gray414241);
			font-size: 14px;
		}

		.textarea-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			border: 1px solid var(--borderGrayECECEC);
			border-radius: 5px;
			overflow: auto;

			textarea {
        font-family: Arial, Helvetica, sans-serif;
        resize: none;
				font-size: 16px;
				letter-spacing: 1px;
				width: 100%;
				border: none;
				padding: 1rem;
				color: var(--gray414241);

        @media screen and (max-width:450px) {
          font-size: 14px;
        }

				&:active,
				&:focus {
					outline: none;
					border: none;
				}

				&:required,
				&:invalid {
					box-shadow: none;
				}
			}

			span {
				cursor: pointer;
				position: absolute;
				right: 20px;
			}
		}

		.error {
			color: red;
			text-align: left;
			display: block;
		}
	}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ContentWrapper',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Contact Us")]},proxy:true}])},[_c('Container',[_c('Form',{staticClass:"contact-form"},[_c('div',{staticClass:"contact-method"},[_c('p',[_vm._v("Would you like us to contact you through email or phone number?")]),_c('RadioButton',{attrs:{"options":[
            {
              id: 'EMAIL',
              label: 'Email',
            },
            {
              id: 'PHONE',
              label: 'Phone Number',
            },
          ],"name":"contact"},model:{value:(_vm.contact_type),callback:function ($$v) {_vm.contact_type=$$v},expression:"contact_type"}}),_c('p',[_vm._v("Subject")]),_c('Input',{attrs:{"id":"subject","type":"text","placeholder":"Write your subject"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('p',[_vm._v("Message")]),_c('TextArea',{attrs:{"id":"message","placeholder":"Write your message...","rows":"5"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('SubmitButton',{on:{"onButtonClick":_vm.sendContactForm}},[_vm._v("Send")])],1),_c('div',{staticClass:"contact-options"},[_c('div',{staticClass:"helpline"},[_c('p',[_vm._v("Kenanga Futures Helpline")]),_c('h3',[_vm._v("+603 2172 3860")])]),_c('div',{staticClass:"email-support"},[_c('p',[_vm._v("Support Email")]),_c('h3',[_vm._v("BCRM@kenanga.com.my")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
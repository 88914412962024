<template>
  <button
    type="submit"
    :class="[
      { 'dark-theme': isDarkTheme },
      { disabled: disabled },
      { 'spacer-right': spacerRight },
      'submit-button',
    ]"
    @click="onClick"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDarkTheme: {
      type: Boolean,
      required: false,
      default: false,
    },
    spacerRight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    disabled(value) {
      this.disabled = value;
    },
  },
  methods: {
    onClick() {
      this.$emit("onButtonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-button {
  display: block;
  border: none;
  background: var(--redC41230);
  color: var(--whiteFFFFFF);
  padding: 0.5rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;

  @media screen and (max-width: 450px) {
    padding: 0.4rem 2rem;
  }

  &.dark-theme {
    background: var(--gray414241);
  }

  &.spacer-right {
    margin-right: 2rem;
  }

  &.full-width {
    width: 100%;
  }

  &.large-spacer {
    padding: 0.8rem 7rem;

    @media screen and (max-width: 450px) {
      padding: 0.8rem 6rem;
    }
  }

  &.center-spacing {
    margin: 3rem auto;

    @media screen and (max-width: 450px) {
      margin: 2rem auto;
    }
  }
}

.disabled {
  background: var(--gray414241) !important;
}
</style>
